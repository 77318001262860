/*import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm";*/
require("./bootstrap");
require('dragscroll/dragscroll.js');

//Alerts
setTimeout(() => {
    $('.alert.notification').remove();
}, 4000);



//PROGRESSIVE SCROLL BAR

$(document).ready(function() {
    var docHeight = $(document).height(),
        windowHeight = $(window).height(),
        scrollPercent;

    $(window).scroll(function() {
        scrollPercent = $(window).scrollTop() / (docHeight - windowHeight) * 100;
        $('.scroll-progress').width(scrollPercent + '%');
    });
});




$(document).ready(function() {

    var docHeight = $(document).height(),
        windowHeight = $(window).height(),
        scrollPercent;

    //Segment
    var segmentPosition = $('.topBrands.segments').offset();
    $('.topBrands.segments .actions .button').first().trigger('click');

    $(window).scroll(function() {
        //Active tabs segments
        if (segmentPosition && ($(window).scrollTop() + (windowHeight / 2)) > segmentPosition.top) {
            $('.topBrands.segments .actions .button:nth-child(2)').trigger('click');
        }

        //PROGRESSIVE SCROLL BAR
        scrollPercent = $(window).scrollTop() / (docHeight - windowHeight) * 100;
        $('.scroll-progress').width(scrollPercent + '%');
    });
});


// Add Class Images With link
$('body .contentBody a img').addClass("notZoom");
$('.contentBody figure img').on('click', function() {
    var link = $(this).parent().find('figcaption a');
    if (link) {
        $(link)[0].click();
        return false;
    }
})

//Zoom image Articles
/*Fancybox.bind("body .contentBody img:not(.notZoom)");*/

/* $(function() {
    $('[data-toggle="tooltip"]').tooltip()
}) */



//Fixed Header Scroll
var didScrollDesk;
var lastScrollTopDesk = 0;
var deltaDesk = 5;

$(window).scroll(function(event) {
    didScrollDesk = true;
});

setInterval(function() {
    if (didScrollDesk) {

        hasScrolled();
        didScrollDesk = false;
    }
}, 250);

function hasScrolled() {
    var st = $(document).scrollTop();

    if (Math.abs(lastScrollTopDesk - st) <= deltaDesk)
        return;

    if (st > lastScrollTopDesk && st >= 200) {
        $('body').addClass('scroll-up');
    } else {
        if (st + $(window).height() < $(document).height()) {
            $('body').removeClass('scroll-up');
        }
    }
    lastScrollTopDesk = st;
}

//MODAL
$('.closeAction').on('click', function() {
    $(this).parents('.modal').hide();
})

$('.openModal').on('click', function() {
    var modal = $(this).data('modal');
    $('.' + modal).show();
})




//search box
const searchBtn = document.querySelector(".search-btn");
const cancelBtn = document.querySelector(".cancel-btn");
const searchBox = document.querySelector(".search-box");

$('.search-btn').on('click', function() {
    $(".search-box").toggleClass('active');
    if ($(".search-box").hasClass("active")) {
        $(".search-box input").focus();
    }
});


//Segments Homepage
$('.topBrands.segments .actions .button').on('click', function() {
    $('.topBrands.segments .actions .button').removeClass('active');
    $(this).addClass('active');

    var segmentId = $(this).data('segment');
    $('.topBrands.segments .segment').hide();
    $('.topBrands.segments .segment[data-segment=' + segmentId + ']').show()
})

//GLOSSARY PAGE
$('.glossaries .filters .alphas ul li').on('click', function() {
    $('.glossaries .filters .alphas ul li').removeClass('active');
    $(this).addClass('active');
})
$('.glossaries .data .group li .topBody').on('click', function() {
    var checkHasClass = $(this).parent().hasClass('active');
    $('.glossaries .data .group li,  .glossaries .data').removeClass('active');

    if (!checkHasClass) {
        $(this).parent().addClass('active');
        $(this).parent().parents('.data').addClass('active');
    }
})
$(".glossaries #searchbar").on('keyup', function() {
    var value = $(this).val().toLowerCase();
    $(".glossaries .group li .name").each(function() {
        if ($(this).text().toLowerCase().search(value) > -1) {
            $(this).parents('li').show();
            //$(this).prev('.country').last().show();
        } else {
            $(this).parents('li').hide();
        }
    });
})


//HEADING POST
$(".body h2").each(function(index) {
    var textH2 = $(this).text();
    $(this).attr("id", string_to_slug(textH2));
    $(this).addClass("elementAnchor");
});

$(".detailsArticle .sidebarNav ul li a.anchor").each(function(index) {
    var textH2 = $(this).data('url');
    $(this).attr("href", "#" + string_to_slug(textH2));
    $(this).attr("id", "mob_" + string_to_slug(textH2));
});

var clickHash = false;
var timeOutHash = "";

var offsetAnchor = 100;


$('.detailsArticle .sidebarNav ul li a.anchor').click(function(event) {
    event.preventDefault();
    var sectionTo = $(this).attr('href');
    clickHash = true;
    if ($(window).width() <= 700) {
        offsetAnchor = 120;
    }

    $('html, body').animate({ scrollTop: $(sectionTo).offset().top - offsetAnchor }, 500);

    clearTimeout(timeOutHash);
    timeOutHash = setTimeout(function() {
        clickHash = false;
    }, 700);



});

var timeoutScrollAnchor = "";
var anchorActive = "";

$(document).scroll(function() {
    if ($(window).width() <= 700) {
        if ($('.listInfosPost').length) {
            var anchorMenuMobile = $('.sideBarInfos');

            var topBodyScroll = $('.listInfosPost').offset().top;

            var st = $(this).scrollTop();

            if (st >= topBodyScroll) {
                anchorMenuMobile.removeClass("hideAnchor");
            } else {
                anchorMenuMobile.addClass("hideAnchor");
            }
        }
    }

    $('.body .elementAnchor').each(function() {
        var top = window.pageYOffset;
        var distance = top - $(this).offset().top;

        var hash = $(this).attr('id');


        var offset = 120;

        if ($(window).width() <= 700) {
            offset = 100;
        }

        if (distance < 40 && distance > (-40 - offset)) {
            $('.detailsArticle .sidebarNav ul li a.anchor').removeClass("active");
            $('.detailsArticle .sidebarNav ul li a.anchor[href="#' + hash + '"]').addClass("active");

            var elem = $('#mob_' + hash);

            if (elem.offset()) {

                if ($(window).width() <= 700) {
                    if (anchorActive != hash) {
                        var btnAnchorID = $(this).attr('id');

                        var anchorLink = $('.anchor'),
                            current = anchorLink.filter('#mob_' + hash),
                            widthElmt = current.width(),
                            index = current.index('.anchor');

                        var container = $(".sidebarNav ul");

                        var withScroll = container.width();

                        var scrollContainer = container.offset().left;

                        var elemtAnchor = $('#mob_' + hash).offset().left;

                        var offsetAnchor = ((withScroll - 208) / 2);

                        var scrollToAnchor = ((index * 208) - offsetAnchor);

                        clearTimeout(timeoutScrollAnchor);

                        timeoutScrollAnchor = setTimeout(function() {

                            $(".sidebarNav").animate({ 'scrollLeft': scrollToAnchor }, 300);
                        }, 500);

                    }

                }

                $('.anchor').removeClass("active");
                elem.addClass("active");
            }

            anchorActive = hash;

        }

    });
});

function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

if (document.location.hash != "") {

    $('.detailsArticle .sidebarNav ul li a.anchor[href="' + document.location.hash + '"]').addClass("active");

}

//Homepage - more affiliate
$('#lastBrands .more').on('click', function() {
    $('#lastBrands .block.d-none').each(function(i) {
        if (i < 6) {
            $(this).removeClass('d-none');
        }
    })
    if (!$('#lastBrands .block.d-none')[0]) {
        $('#lastBrands .more').hide();
    }
})

$('#otherBrands .more').on('click', function() {
    $('#otherBrands .block.d-none').each(function(i) {
        if (i < 6) {
            $(this).removeClass('d-none');
        }
    })
    if (!$('#otherBrands .block.d-none')[0]) {
        $('#otherBrands .more').hide();
    }
})